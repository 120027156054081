import fetchApi from '../helpers/fetch-api';

const fetchStats = (targetValue, block) => {
	const statsMin = block.querySelector('.stats-min');
	const statsAvg = block.querySelector('.stats-avg');
	const statsMax = block.querySelector('.stats-max');

	const aliviaDays = block.getAttribute('data-alivia-days');

	let asap = 1;
	const statsAsap = document.getElementById('stats-asap');
	console.log('asap 1: ', asap);
	if (statsAsap) {
		asap = statsAsap.value;
	}
	console.log('asap 2: ', asap);

	let date = new Date();
	date = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

	if (!aliviaDays && !date && !asap) return;

	// /api/v1/stats/waiting-times?days=60&dateTo=2022-10-20&queueId=1&serviceId=217
	fetchApi(
		'GET',
		// eslint-disable-next-line no-undef
		`${appVariables.apiUrl}/api/v1/stats/waiting-times?days=${aliviaDays}&dateTo=${date}&queueId=${asap}&serviceId=${targetValue}`,
	).then((response) => {
		if (response.data) {
			statsMin.innerHTML = Math.floor(response.data.minDaysUntilExamination);
			statsAvg.innerHTML = Math.floor(response.data.avgDaysUntilExamination);
			statsMax.innerHTML = Math.floor(response.data.maxDaysUntilExamination);
		}
	});
};

const fetchStats2 = (queue = 1, block) => {
	const services = block.querySelectorAll('.b-stats__item');
	if (services.length > 0) {
		services.forEach((element) => {
			fetchApi(
				'GET',
				`${
					// eslint-disable-next-line no-undef
					appVariables.apiUrl
				}/api/v1/stats/waiting-times-by-service?queueId=${queue}&serviceId=${element.getAttribute('data-id')}`,
			).then((response) => {
				if (response.data.length > 0) {
					const top = element.querySelector('.stats-2-top');
					if (top) {
						top.innerHTML = response.data[0].service.name;
					}
					const day = element.querySelector('.stats-2-day');
					if (day) {
						day.innerHTML = response.data[0].avg;
					}
				}
			});
		});
	}
};
const blockStats = () => {
	const block = document.querySelector('.b-stats--v1');

	if (block) {
		/**
		 * Select
		 */
		const stats = document.getElementById('stats-v1');
		const statsAsap = document.getElementById('stats-asap-v1');

		if (stats) {
			// eslint-disable-next-line no-undef
			fetchApi('GET', `${appVariables.apiUrl}/api/v1/services?active=1`).then((response) => {
				if (response.data) {
					if (response.data.length > 0) {
						response.data.forEach((serviceData) => {
							const option = document.createElement('option');
							option.text = serviceData.name;
							option.value = serviceData.id;
							stats.appendChild(option);
						});
					}
				}
				if (stats.options.length > 0) {
					fetchStats(stats.options[0].value, block);
				}
			});

			stats.addEventListener('change', (e) => {
				const targetValue = e.currentTarget.value;
				fetchStats(targetValue, block);
			});

			if (statsAsap) {
				statsAsap.addEventListener('change', (e) => {
					if (e.currentTarget.checked) {
						statsAsap.value = 2;
					} else {
						statsAsap.value = 1;
					}
					fetchStats(stats.value, block);
				});
			}
		}
	}

	const block2 = document.querySelector('.b-stats--v2');
	const stats2radios = document.querySelectorAll('.stats-2-radio');

	if (block2 && stats2radios.length > 0) {
		fetchStats2(1, block2);
		stats2radios.forEach((element) => {
			element.addEventListener('change', (e) => {
				const elVal = e.currentTarget.value;
				const allLink = document.querySelectorAll('.b-stats .b-stats__item--link');
				if (allLink && elVal) {
					allLink.forEach((stat) => {
						const atrHref = stat.getAttribute('href');
						if (atrHref) {
							const currentUrl = new URL(atrHref);
							const { searchParams } = currentUrl;
							const queueId = searchParams.get('queueId');

							if (queueId === '1' || queueId === '2') {
								searchParams.set('queueId', elVal);
								const newUrl = currentUrl.toString();
								stat.setAttribute('href', newUrl);
							}
						}
					});
				}
				fetchStats2(e.currentTarget.value, block2);
			});
		});
	}
};
export default blockStats;
